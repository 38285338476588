@font-face {
	font-family: 'flaticon';
	src:  url('../fonts/flaticon.eot?9r9t2p');
	src:  url('../fonts/flaticon.eot?9r9t2p#iefix') format('embedded-opentype'),
	url('../fonts/flaticon.ttf?9r9t2p') format('truetype'),
	url('../fonts/flaticon.woff?9r9t2p') format('woff'),
	url('../fonts/flaticon.svg?9r9t2p#flaticon') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

[class^="flaticon-"], [class*="flaticon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'flaticon' !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.flaticon-map:before {
	content: "\e900";
}
.flaticon-location:before {
	content: "\e902";
}
.flaticon-ok:before {
	content: "\e800";
}
.flaticon-search:before {
	content: "\e801";
}
.flaticon-cancel:before {
	content: "\e802";
}
.flaticon-plus:before {
	content: "\e803";
}
.flaticon-down-open:before {
	content: "\e804";
}
.flaticon-left-open:before {
	content: "\e805";
}
.flaticon-right-open:before {
	content: "\e806";
}
.flaticon-up-open:before {
	content: "\e807";
}
.flaticon-play-circled2:before {
	content: "\e808";
}
.flaticon-tiktok:before {
	content: "\e809";
}
.flaticon-facebook:before {
	content: "\f09a";
}
.flaticon-linkedin:before {
	content: "\f0e1";
}
.flaticon-angle-left:before {
	content: "\f104";
}
.flaticon-angle-right:before {
	content: "\f105";
}
.flaticon-angle-up:before {
	content: "\f106";
}
.flaticon-angle-down:before {
	content: "\f107";
}
.flaticon-circle-empty:before {
	content: "\f10c";
}
.flaticon-circle:before {
	content: "\f111";
}
.flaticon-play-circled:before {
	content: "\f144";
}
.flaticon-youtube:before {
	content: "\f167";
}
.flaticon-youtube-play:before {
	content: "\f16a";
}
.flaticon-instagram:before {
	content: "\f16d";
}
.flaticon-tumblr:before {
	content: "\f173";
}
.flaticon-paper-plane:before {
	content: "\f1d8";
}
.flaticon-pinterest:before {
	content: "\f231";
}
.flaticon-map-o:before {
	content: "\f278";
}
.flaticon-vimeo:before {
	content: "\f27d";
}
.flaticon-user-circle:before {
	content: "\f2bd";
}
.flaticon-user-o:before {
	content: "\f2c0";
}
.flaticon-x:before {
	content: "\f099";
}
.flaticon-twitter:before {
	content: "\f099";
}
.flaticon-comment32:before {
	content: "\e04b";
}
.flaticon-envelope4:before {
	content: "\e069";
}
.flaticon-envelope5:before {
	content: "\e06a";
}
.flaticon-remove11:before {
	content: "\e0fc";
}
