@charset "utf-8";
@import "settings";
//@import "assets/components/foundation/scss/foundation";
//@import "assets/components/motion-ui/motion-ui";
@import "assets/vendor/foundation/foundation-sites/scss/foundation";
@import "flaticon";
@import "slick";
// Or selectively include components
@include foundation-global-styles;
//@include foundation-grid;
@include foundation-flex-grid;
@include foundation-xy-grid-classes;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-card;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
//@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
//@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
//@include foundation-float-classes;
@include foundation-flex-classes;
// @include foundation-prototype-classes;
//@include motion-ui-transitions;
//@include motion-ui-animations;
// FOR IE 10
@-ms-viewport {
  width: device-width;
}
// FONTS
//////////////////////////////////////////////////////////////////////////
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600');
@font-face {
  font-family: 'Didot';
  src: url("../fonts/theanodidot-regular.woff2") format("woff2"), url("../fonts/theanodidot-regular.woff") format("woff"), url("../fonts/theanodidot-regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
// @font-face {
//    font-family: 'Didot';
//    src: url('../fonts/Didot.eot?#iefix') format('embedded-opentype'), url("../fonts/Didot.woff") format("woff") ,url("../fonts/Didot.woff2") format("woff2"), url("../fonts/Didot.ttf") format("truetype"), url("../fonts/Didot.svg#Didot") format("svg");
//    font-weight: normal;
//    font-style: normal;
// }
@font-face {
  font-family: 'Gotham';
  src: url('../fonts/GothamBook.eot?#iefix') format('embedded-opentype'), url('../fonts/GothamBook.otf') format('opentype'), url("../fonts/GothamBook.woff") format("woff"), url("../fonts/GothamBook.ttf") format("truetype"), url("../fonts/GothamBook.svg#GothamMedium") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Gotham';
  src: url('../fonts/GothamMedium.eot?#iefix') format('embedded-opentype'), url('../fonts/GothamMedium.otf') format('opentype'), url("../fonts/GothamMedium.woff") format("woff"), url("../fonts/GothamMedium.ttf") format("truetype"), url("../fonts/GothamMedium.svg#GothamMedium") format("svg");
  font-weight: bold;
  font-style: normal;
}
// MIXINS
//////////////////////////////////////////////////////////////////////////
@function f-header-size($breakpoint, $size) {
  @return rem-calc(map-get(map-get(map-get($header-styles, $breakpoint), $size), "font-size") * 1px);
}
@mixin header-size($type) {
  font-size: rem-calc(map-get(map-get(map-get($header-styles, "small"), $type), "font-size") * 1px);
  @include breakpoint(medium) {
    font-size: rem-calc(map-get(map-get(map-get($header-styles, "medium"), $type), "font-size") * 1px);
  }
}
@function gutter($size) {
  @return rem-calc(map-get($grid-column-gutter, $size) * 1px);
}
@function button($size) {
  @return rem-calc(map-get($button-sizes, $size) * 1px);
}
@mixin stak {
  // defaults;
  padding: 20px 0;
  p {
    font-size: 1em;
    &.lead {
      font-size: 100%;
      line-height: 1.6;
    }
  }
  @include breakpoint(medium) {
    // enhancements;
    padding: 40px 0;
  }
  @include breakpoint(large) {
    // enhancements;
    padding: 60px 0;
    p {
      font-size: 1em;
      &.lead {
        font-size: 125%;
        line-height: 1.6;
      }
    }
  }
}
@mixin hero-slide {
  // defaults;
  height: 300px;
  background-repeat: no-repeat;
  background-size: cover !important;
  z-index: 0;
  overflow: hidden;
  display: block;
  background-position: center center;
  backface-visibility: hidden;
  @media only screen and (max-device-width: 740px) {
    height: 200px;
  }
  @include breakpoint(medium) {
    // enhancements;
    //height: 600px;
    height: 350px;
  }
  @include breakpoint(large) {
    // enhancements;
    //height: 700px;
    height: 600px;
  }
  @include breakpoint(xlarge) {
    // enhancements;
    height: 700px;
  }
  @include breakpoint(xxlarge) {
    // enhancements;
    height: 800px;
  }
}
@mixin no-bullet {
  list-style-type: none;
  margin: 0;
}
@mixin stak-pic {
  @include stak;
  display: table;
  width: 100%;
  min-height: 300px;
  .row {
    display: table-row;
    width: 100%;
  }
  .columns {
    display: table-cell;
    float: none;
    vertical-align: middle;
    text-align: center;
  }
  .column:last-child:not(:first-child), .columns:last-child:not(:first-child) {
    float: none;
  }
  .vertical-center {
    vertical-align: middle;
  }
  h2 {
    //color: $white;
    line-height: initial;
    span {
      background-color: rgba($body-font-color, 0.8);
      padding: 0 0.25em;
      margin: 0;
      box-decoration-break: clone;
      -webkit-box-decoration-break: clone;
    }
    strong {
      color: $black;
      display: inline-block;
    }
  }
  h3, li, p, strong {
    //color: $white;
  }
  @include breakpoint(large) {
    background-attachment: fixed;
  }
}
@mixin overlay {
  &:before {
    content: "";
    background: rgba($primary-color, .3);
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 0;
  }
}
@mixin overlay-solid {
  content: "";
  //background: rgba($gray, .5);
  position: absolute;
  mix-blend-mode: multiply;
  opacity: .1;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 1;
  height: 100%;
  width: 100%;
}
@mixin fixed {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
  left: 0;
  &.expanded:not(.top-bar) {
    height: auto;
    max-height: 100%;
    overflow-y: auto;
    width: 100%;
  }
  &.expanded:not(.top-bar) .title-area {
    position: fixed;
    width: 100%;
    z-index: 99;
  }
  &.expanded:not(.top-bar) .top-bar-section {
    margin-top: 5rem;
    z-index: 98;
  }
}
@mixin radius {
  border-radius: 3px;
}
@mixin box-shadow {
  box-shadow: 0px 8px 12px 0px rgba($gray, 0.5);
}
.foo {
  @include grid-row;
  .bar {
    @include grid-column(12);
  }
}
@media screen and #{breakpoint(medium)} {
  .foo {
    @include grid-row;
    .bar {
      @include grid-column(4);
    }
  }
}
// DEFAULTS (* see _settings.scss for FOUNDATION OVERRIDES)
//////////////////////////////////////////////////////////////////////////
$logo-mobile: 40px;
$logo-small: 100px;
$logo-medium: 120px;
$logo-large: 140px;
html {
  scroll-behavior: smooth;
}
.overlay {
  @include overlay;
}
h2, h3 {
  text-transform: uppercase;
}
h3 {
  //font-weight: bold;
  font-family: $font-family-serif;
}
h4 {
  text-transform: uppercase;
  color: $red;
  font-weight: bold;
  font-family: $header-font-family;
}
.cyan {
  color: $cyan;
}
.blue {
  color: $blue;
}
.red {
  color: $red;
}
.bgd-blue {
  background-color: $blue;
}
address {
  font-style: normal;
  color: $gray;
  margin-bottom: 2em;
  strong {
    color: $black;
  }
}
.callout {
  &.border {
    border-top: 4px solid $light-gray;
    @include breakpoint(medium) {
      border-top: none;
      border-left: 4px solid $light-gray;
    }
  }
}
.columns {
  position: relative;
}
.fixed {
  position: fixed;
  width: 100%;
  z-index: 1;
}
$off-canvas-width: 60%;
.row.expanded {
  max-width: none;
}
:focus {
  outline: none;
}
a.has-tip {
  border-bottom: none;
  color: $primary-color;
  cursor: pointer;
  font-weight: normal;
}
.slick-arrow {
  z-index: 1;
}
.go-button {
  @include button;
  @include header-size(h5);
  margin-top: 1em;
  color: $white !important;
  overflow: hidden;
  width: 250px;
  cursor: pointer;
  white-space: nowrap;
  transition-timing-function: linear;
  transition: all 0.2s ease;
  margin: 0 auto 1em;
  position: relative;
  &:before {
    content: '';
    background: darken($primary-color, 10%) url("../images/site/arrowhead.png") center center no-repeat;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    transition-timing-function: linear;
    transform: translateZ(0) scale(1, 1.1) translate(-101%, 0);
    transition: all 0.2s ease-in-out 0s;
  }
  &:hover:before {
    transform: translateZ(0) scale(1, 1.1) translate(0%, 0);
  }
}
ul.link-list {
  list-style: none;
  margin: 0 auto;
  li:before {
    content: "\203A \20";
    padding-left: 0.25em;
  }
  li:hover:before {
    color: $cyan;
  }
  a {
    display: inline-block;
    &:after {
      content: '';
      display: block;
      height: 3px;
      width: 0;
      margin: 0.25em 0 0;
      background: transparent;
      transition: width 0.25s ease, background-color 0.25s ease;
    }
    &:hover:after {
      width: 100%;
      background: $red;
    }
  }
}
ul.box-list {
  list-style: none;
  margin: 0 0 1em 0;
  li {
    display: block;
    background-color: lighten($light-gray, 10%);
    border: 1px solid $light-gray;
    margin: 0;
    padding: 1em;
    color: $gray;
    font-weight: bold;
    strong {
      display: inline-block;
      padding: 0 .5em 0 0;
      font-weight: normal;
      text-transform: uppercase;
      color: $dark-gray;
      &:after {
        content: ':';
      }
    }
  }
}
dl.def-list {
  list-style: none;
  margin: 1em 0;
  dt {
    color: $cyan;
    font-style: italic;
    font-family: $font-family-serif;
    margin: 0;
    padding: 0;
    line-height: 1em;
    font-weight: lighter;
    @include breakpoint(medium) {
      font-size: 1.5em;
    }
  }
  dd {
    margin: 0;
    padding: 0 0 1em 0;
    text-transform: uppercase;
    //color: $dark-gray;
  }
}
.button {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
}
.button.hollow {
  &:hover {
    color: $white;
    background-color: $red;
  }
}
.stak-pic {
  @include stak-pic;
}
.diamond-red {
  position: relative;
  z-index: 1;
  &:before {
    z-index: 0;
    content: " ";
    opacity: .3;
    width: 0;
    height: 0;
    border-top: 100px solid transparent;
    border-right: 100px solid $red;
    border-bottom: 100px solid transparent;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    @include breakpoint(medium) {
      border-top: 200px solid transparent;
      border-right: 200px solid $red;
      border-bottom: 200px solid transparent;
    }
  }
}
// prevent mobile no-scroll
.slick-slider, .slick-list {
  touch-action: auto !important;
  -ms-touch-action: auto;
}
// GLOBALS (CUSTOM GLOBALS)
//////////////////////////////////////////////////////////////////////////
header.global {
  background-color: rgba($white, 0);
  transition: all 0.25s;
  position: relative;
  .logo {
    width: $logo-small;
    padding: 0em 2em 0 0em;
    transition: all 0.25s;
  }
  @include breakpoint(medium) {
    position: fixed;
    width: 100%;
    z-index: 10;
    top: 0;
    .logo {
      padding: 0em 2em 0 1em;
      //width: $logo-medium;
      //max-width: $logo-medium;
    }
    &.investments {
      position: relative;
    }
  }
  @include breakpoint(large) {
    // enhancements;
    .logo {
      width: $logo-large;
    }
  }
  @include breakpoint(xlarge) {
    // enhancements;
    .logo {
      padding: 0em 2em 0 1em;
    }
  }
  // GLOBAL-MENU ///////////////////////////////////////////////
  ul.global-menu {
    text-align: right;
    float: right;
    > li {
      float: left;
    }
    > li > a {
      font-size: 0.85em;
      padding: 0 .8em;
      color: $white;
      line-height: 6rem;
      text-transform: uppercase;
      font-weight: normal;
      transition: all 0.5s;
      font-weight: bold;
      font-family: $header-font-family;
      &:hover {
        color: $secondary-color;
      }
      &:after {
        content: '';
        display: block;
        height: 3px;
        width: 0;
        margin: 0;
        background: transparent;
        transition: width 0.25s ease, background-color 0.25s ease;
      }
      &:hover:after {
        width: 100%;
        background: $red;
      }
      &.sel {
        &:after {
          width: 100%;
          background: $red;
        }
      }
      @include breakpoint(large) {
        font-size: 1.2em;
      }
    }
    > li:first-of-type > a {
      padding: 0 1em 0 0;
    }
    > li:last-of-type > a {
      padding: 0 0 0 1em;
    }
    /* Remove content top border, align to left */
  }
  a.ico, button.ico, i {
    color: $gray;
    position: relative;
    &:hover {
      color: lighten($primary-color, 10);
    }
    &:before {
      content: "";
      width: 20px;
      height: 20px;
      display: inline-block;
      font-family: "flaticon";
      transition: all .25s;
      color: $white;
    }
    &.instagram {
      &:before {
        content: "\f16d";
      }
    }
    &.facebook {
      &:before {
        content: "\f09a";
      }
    }
    &.twitter {
      &:before {
        content: "\f099";
      }
    }
    &.youtube {
      &:before {
        content: "\f16a";
      }
    }
    &.directory {
      &:before {
        content: "\f278";
      }
    }
    &.directions {
      font-size: .75em;
      text-transform: uppercase;
      position: relative;
      &:before {
        content: "";
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 40'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23ffffff%7D%3C/style%3E%3C/defs%3E%3Cg id='Layer_2' data-name='Layer 2'%3E%3Cg id='Layer_1-2' data-name='Layer 1'%3E%3Cpath class='cls-1' d='M21.8 10.62h-5.63v9.93h5.63c3.67 0 5.95-2 5.95-5 0-3.24-2.32-4.93-5.95-4.93z'/%3E%3Cpath class='cls-1' d='M20 0a20 20 0 1020 20A20 20 0 0020 0zm1.65 24.93h-5.48v8.86h-4.85V6.21H22.2c6.42 0 10.48 3.62 10.48 9.26 0 6.22-4.96 9.46-11.03 9.46z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        width: 18px;
        height: 18px;
        transition: all .25s;
      }
      &:hover {
        &:before {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 40'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23ba0c2f%7D%3C/style%3E%3C/defs%3E%3Cg id='Layer_2' data-name='Layer 2'%3E%3Cg id='Layer_1-2' data-name='Layer 1'%3E%3Cpath class='cls-1' d='M21.8 10.62h-5.63v9.93h5.63c3.67 0 5.95-2 5.95-5 0-3.24-2.32-4.93-5.95-4.93z'/%3E%3Cpath class='cls-1' d='M20 0a20 20 0 1020 20A20 20 0 0020 0zm1.65 24.93h-5.48v8.86h-4.85V6.21H22.2c6.42 0 10.48 3.62 10.48 9.26 0 6.22-4.96 9.46-11.03 9.46z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        }
      }
    }
    &.contact {
      &:before {
        content: "\e06a";
      }
    }
    span {
      display: none;
    }
  }
  &.shrink {
    background-color: rgba($white, 0.9);
    height: auto;
    line-height: 2em;
    ul.global-menu > li > a {
      color: $gray;
      line-height: 3rem;
      &:hover {
        color: $secondary-color;
      }
    }
    .logo {
      width: $logo-small;
    }
    a.ico, button.ico, i {
      &.instagram {
        &:before {
          color: $cyan;
        }
        &:hover:before {
          color: darken($cyan, 20%);
        }
      }
      &.facebook {
        &:before {
          color: $red;
        }
        &:hover:before {
          color: darken($red, 20%);
        }
      }
      &.twitter {
        &:before {
          color: $blue;
        }
        &:hover:before {
          color: lighten($blue, 30%);
        }
      }
      &.youtube {
        &:before {
          color: $red;
        }
        &:hover:before {
          color: lighten($red, 30%);
        }
      }
      &.directory {
        &:before {
          color: $blue;
        }
        &:hover:before {
          color: lighten($blue, 30%);
        }
      }
      &.directions {
        font-size: .75em;
        text-transform: uppercase;
        position: relative;
        &:before {
          content: "";
          background: url("../images/site/ico-parking.svg") no-repeat;
          color: $red;
          width: 18px;
          height: 18px;
          //transition: all .25s;
        }
        &:hover:before {
          color: darken($red, 20%);
          transform: scale(1.1);
        }
      }
      &.contact {
        &:before {
          color: $secondary-color;
        }
        &:hover:before {
          color: darken($secondary-color, 20%);
        }
      }
    }
  }
  &.mega-menu {
    .dropdown-pane.mega {
      width: 100% !important;
      font-size: .9em;
      border: none;
      border-radius: 0;
      border-bottom: 8px solid $light-gray;
      &.is-open {
        max-width: 100% !important;
        width: 100% !important;
        left: 0 !important;
        display: block;
      }
      a {
        font-size: 1em;
        &:hover p {
          color: lighten($primary-color, 30%) !important;
          text-decoration: underline;
        }
        .title {
          color: $body-font-color;
          font-family: $font-family-serif;
          text-transform: uppercase;
          //letter-spacing: .25px;
          line-height: 1.15em;
          display: block;
        }
        .subtitle {
          font-family: $header-font-family;
          text-transform: uppercase;
          font-weight: bold;
          line-height: 1.25em;
          display: block;
        }
        p {
          font-size: 1em;
          color: $body-font-color;
        }
        .thumbnail {
          width: 100%;
          //max-height: 80px
        }
        @include breakpoint(large) {
          &.title {
            font-size: 1.15em;
          }
        }
      }
      .title {
        color: $body-font-color;
        font-family: 'Merriweather', serif;
        font-size: 0.9em;
        //margin-top: 1em;
        margin-bottom: 0em;
        @include breakpoint(large) {
          font-size: 1.5em;
        }
      }
      img {
        width: 100%;
        margin: 0.75em 0;
        display: block;
      }
      .button {
        padding-left: 1em;
        padding-right: 1em;
        padding-bottom: 0.675rem;
      }
      input {
        height: 2.3125rem;
      }
      ul li {
        float: none;
        margin: 0.75em 0;
        a {
          line-height: 1.25em;
          img {
            margin: 0.75em 0;
            display: block;
          }
          &:hover {
            img {
              opacity: 0.9;
            }
          }
        }
      }
      .extra {
        border-left: 1px solid #efefef;
      }
    }
    .light-gray {
      background-color: $snow;
      .extra {
        padding-top: 1em;
      }
    }
  }
  .vertical.menu {
    a {
      &:hover {
        color: $secondary-color;
        text-decoration: underline;
      }
    }
  }
}
.ancillary {
  text-align: right;
  ul {
    margin: 0 !important;
    font-size: 1.25em;
    padding: 0.25em 0 0 0;
    display: inline-block;
    list-style-type: none;
    li {
      display: inline;
      list-style-type: none;
      padding: 0 .5em;
      &:last-of-type {
        padding: 0;
      }
    }
    .divider {
      border-left: 1px solid $medium-gray;
      margin-left: .5em;
    }
  }
}
a.ico, button.ico, i {
  color: $gray;
  position: relative;
  &:hover {
    color: lighten($primary-color, 10);
  }
  &:before {
    content: "";
    width: 20px;
    height: 20px;
    display: inline-block;
    font-family: "flaticon";
    transition: all .25s;
  }
  &.instagram {
    &:before {
      content: "\f16d";
      color: $cyan;
    }
    &:hover:before {
      color: darken($cyan, 20%);
    }
  }
  &.facebook {
    &:before {
      content: "\f09a";
      color: $red;
    }
    &:hover:before {
      color: darken($red, 20%);
    }
  }
  &.twitter {
    &:before {
      content: "\f099";
      color: $blue;
    }
    &:hover:before {
      color: lighten($blue, 30%);
    }
  }
  &.youtube {
    &:before {
      content: "\f16a";
      color: $red;
    }
    &:hover:before {
      color: lighten($red, 30%);
    }
  }
  &.directory {
    &:before {
      content: "\f278";
      color: $blue;
    }
    &:hover:before {
      color: lighten($blue, 30%);
    }
  }
  //&.directions {
  //  font-size: .75em;
  //  text-transform: uppercase;
  //  position: relative;
  //  &:before {
  //    content: "";
  //    background: url("../images/site/ico-parking.svg") no-repeat;
  //    color: $red;
  //    width: 18px;
  //    height: 18px;
  //    //transition: all .25s;
  //  }
  //  &:hover:before {
  //    color: darken($red, 20%);
  //    transform: scale(1.1);
  //  }
  //}
  &.event-parking {
    font-size: .75em;
    text-transform: uppercase;
    position: relative;
    &:before {
      content: "";
      background: $red url("../images/site/ico-p-warning.svg") no-repeat center center;
      border-radius: 100%;
      width: 1.15em;
      height: 1.15em;
      background-size: 50%;
      box-sizing: content-box;
      //transition: all .25s;
    }
  }
  &.contact {
    &:before {
      content: "\e06a";
      color: $secondary-color;
    }
    &:hover:before {
      color: darken($secondary-color, 20%);
    }
  }
  span {
    display: none;
  }
}
.sold-out {
  color: $white;
  padding: .25em .5em !important;
  text-align: center;
  background-color: $red;
  font-weight: 600;
}
.tribe-events-pro-photo__event-featured-image-wrapper {
  position: relative;
  .sold-out {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
}
.tribe-events-pro-week-grid__event {
  .sold-out {
    display: block;
  }
}
// MOBILE NAVIGATION
/////////////////////////////////////
.title-bar {
  text-align: center;
  padding: 0.5em 0 0.25em;
  line-height: 1em;
  .logo {
    width: $logo-mobile;
    margin: 0 auto;
    padding: 0;
    .fill-black {
      fill: $graphite;
    }
  }
  .menu-ico {
    //margin: -2px 0.3em;
    border: none;
    background: none;
    z-index: 99999;
    transform: scale3d(0.8, 0.8, 0.8);
    transition: 0.3s;
    cursor: pointer;
    user-select: none;
    position: absolute;
    right: 10px;
    top: 1.5em;
    span {
      display: inline-block;
      width: 2.5rem;
      height: 0.26rem;
      background: $primary-color;
      border-radius: 0.5em;
      transition: 0.3s;
      position: relative;
    }
    span:before {
      display: inline-block;
      width: 2.5rem;
      height: 0.26rem;
      background: $primary-color;
      border-radius: 0.5em;
      transition: all 0.3s;
      position: absolute;
      top: 0.75em;
      left: 0;
      content: '';
      transform-origin: 0.13rem center;
    }
    span:after {
      display: inline-block;
      width: 2.5rem;
      height: 0.26rem;
      background: $primary-color;
      border-radius: 0.5em;
      transition: all 0.3s;
      position: absolute;
      top: -.75em;
      left: 0;
      content: '';
      transform-origin: 0.13rem center;
      //  box-shadow: 0 0 0 1px $white, 0 7px 0 1px $white, 0 14px 0 1px $white;
    }
    &:hover {
      span {
        background: lighten($secondary-color, 10%);
      }
      span:before {
        background: lighten($secondary-color, 10%);
      }
      span:after {
        background: lighten($secondary-color, 10%);
        //  box-shadow: 0 0 0 1px $white, 0 7px 0 1px $white, 0 14px 0 1px $white;
      }
    }
  }
}
.menu-ico[aria-expanded="true"] {
  //background-color: $primary-color;
  span {
    background: transparent !important;
  }
  span:before {
    //box-shadow: 0 0 0 1px $white, 0 7px 0 1px $white, 0 14px 0 1px $white;
    transform: rotate3d(0, 0, 1, 45deg);
    transform-origin: 50% 50%;
    top: 0;
    background-color: $secondary-color;
  }
  span:after {
    //box-shadow: 0 0 0 1px $white, 0 7px 0 1px $white, 0 14px 0 1px $white;
    transform: rotate3d(0, 0, 1, -45deg);
    transform-origin: 50% 50%;
    top: 0;
    background-color: $secondary-color;
  }
}
.off-canvas.is-transition-overlap {
  z-index: 90000;
}
.right-off-canvas-menu {
  padding-top: 3.75rem;
  //background-color: rgba($jet, .9);
  .menu li a {
    display: block;
    border-bottom-color: darken($blue, 10%);
    border-top: 1px solid rgba($white, 0.15);
    color: $white;
    &:hover {
      color: $secondary-color;
    }
  }
  .menu li:first-of-type {
    a {
      border-top: none;
    }
  }
  .menu li:last-of-type {
    a {
      border-bottom: none;
    }
  }
}
// GLOBAL FOOTER
/////////////////////////////////////
footer.global-footer {
  padding: 2em 0 6em 0;
  font-size: .9em;
  //*{color: $body-font-color;}
  text-align: center;
  position: relative;
  background: url('../images/site/footer-diamond-left.gif') bottom left no-repeat;
  background-size: 140px;
  z-index: 1;
  &:after {
    content: "";
    z-index: -1;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    display: inline-block;
    background: url('../images/site/footer-diamond-right.gif') bottom right no-repeat;
    background-size: 140px;
  }
  h5 {
    font-weight: bold;
    margin-top: 1em;
    text-transform: uppercase;
  }
  .logo {
    vertical-align: middle;
    margin: 0 auto;
    max-width: 30%;
  }
  ul.link-list {
    text-transform: uppercase;
    text-align: left;
    margin-bottom: 1em;
  }
  .copyright {
    margin: 1em auto;
    p {
      font-size: 0.8em;
      color: $gray
    }
  }
  .mobile-tools {
    @include no-bullet;
    margin: .5em 0;
    li {
      display: inline-block;
      margin: 0 .5em;
      img {
        width: 30px;
      }
    }
  }
  .social {
    text-align: center;
    ul {
      @include no-bullet;
      li {
        display: inline-block;
        a {
          font-size: 1.5em;
          display: block;
          position: relative;
          margin: 0 .5em;
          &:hover:after {
            content: "";
            position: absolute;
            width: 100%;
            height: 1px;
            background-color: $red;
            bottom: 0;
            left: .25em;
          }
        }
      }
    }
  }
  .social-icons {
    [class^="flaticon-"]:hover:after, [class*="flaticon-"]:hover:before {
      color: lighten($primary-color, 50%);
    }
  }
  .jobs-btn {
    @include button($expand: true, $background: $white, $style: hollow);
    transition: all, .25s;
    border-width: 2px;
    &:hover {
      color: lighten($primary-color, 50%);
      border-color: lighten($primary-color, 50%);
    }
  }
  @include breakpoint(medium) {
    padding: 4em 0 6em 0;
    text-align: left;
    .social {
      border-left: 1px solid $red;
      border-right: 1px solid $red;
    }
    .logo {
      margin: 0;
      max-width: 70%;
    }
  }
  @include breakpoint(large) {
    background-size: 200px;
    &:after {
      background-size: 200px;
    }
  }
  @include breakpoint(xlarge) {
    background-size: 240px;
    &:after {
      background-size: 240px;
    }
  }
}
.icon-callouts {
  img {
    padding-right: 0.5em;
  }
  a:hover {
    h4 {
      color: $primary-color;
    }
  }
}
.stak {
  @include stak;
  //  background-color: $white;
}
.alt-stak {
  @include stak;
  background-color: $snow;
}
.blue-stak {
  @include stak;
  background-color: $cyan;
  * {
    color: $white;
  }
  hr {
    border-bottom: 1px solid rgba($white, .25);
  }
}
.video-thumbnail {
  position: relative;
  padding: 0;
  width: 100%;
  overflow: hidden;
  transition: all .3s;
  display: block;
  .poster {
    //max-height: 300px;
    width: 100%;
    overflow: hidden;
    display: block;
    position: relative;
    background-color: $primary-color;
    img {
      height: auto;
      max-width: 100%;
      opacity: .5;
      width: 100%;
      transition: all .4s;
    }
    .x-content {
      margin: auto;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      text-align: center;
      height: 30px;
      width: 100%;
      opacity: 1;
      transition: all .4s;
      background-color: rgba(0, 0, 0, 0);
      .view {
        font-weight: 600;
        color: $white;
        font-size: 1.5em;
        text-transform: uppercase;
        h5 {
          display: block;
          margin: -40px 8px 0 8px;
          color: $white;
          font-weight: bold;
        }
        i {
          line-height: 1em;
          font-size: 60px;
          margin: 0 auto;
          padding: 0;
          &:before {
            width: 60px;
            font-size: 1em;
            margin-left: -30px;
          }
        }
      }
    }
  }
  &:hover {
    .poster {
      .x-content {
        opacity: 1;
        > div > div {
          transform: translate(0px, 0px);
        }
        i {
          &:before {
            color: $secondary-color;
          }
        }
      }
      img {
        transform: scale(1.25, 1.25);
        opacity: .25;
      }
      .meta {
        background-color: lighten($light-gray, 12);
      }
    }
  }
}
.responsive-embed, .flex-video {
  margin-bottom: 0;
  @include box-shadow;
  iframe {
    border: none;
  }
}
// HOMEPAGE
//////////////////////////////////////////////////////////////////////////
.callout.alert {
  font-size: 80%;
  background-color: $red;
  color: $white !important;
  h1, h2, h3, h4, h5, p {
    color: $white !important;
  }
  a {
    display: block;
    position: relative;
    &:hover {
      h1, h2, h3, h4, h5, p {
        color: $cyan !important;
      }
    }
  }
  .close-button {
    color: $white !important;
    &:hover {
      opacity: .5;
    }
  }
  @include breakpoint(large) {
    font-size: 100%;
  }
}
#home-top-bar {
  width: 100%;
}
.home-hero {
  .audio-btn.button.hollow {
    position: absolute;
    bottom: 1em;
    right: 1em;
    z-index: 9999;
    border-color: $white;
    color: $white;
    &:before {
      content: "\e160";
      font-family: Flaticon;
    }
    @media only screen and (max-device-width: 640px) {
      border: none;
      color: $primary-color;
      font-size: .8em;
      right: 0;
      bottom: 2em;
      &.audio-off {
        &:focus, &:hover, :active {
          background-color: transparent;
          color: $secondary-color;
        }
      }
      &.audio-on {
        &:focus, &:hover, :active {
          background-color: transparent;
          color: $primary-color;
        }
      }
    }
  }
  @include hero-slide;
  .loading {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .overlay {
    content: "";
    background: rgba($primary-color, .5);
    //background: linear-gradient(90deg, rgba(26, 47, 118, 1) 0%, rgba(0, 124, 186, 1) 100%);
    position: absolute;
    mix-blend-mode: normal;
    opacity: .75;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 0;
    height: 100%;
    width: 100%;
  }
  .video {
    object-fit: cover;
    position: absolute;
    background-size: cover;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 0;
    display: block;
    //margin-bottom: -6px;
    overflow: visible;
  }
  @supports (-ms-ime-align:auto) {
    .video {
      background-size: 100%;
    }
  }
}
.carousel-hero {
  display: none;
  opacity: 0;
  transition: opacity 0.25s ease-in;
  .home-banner .slide .video-slide {
    background-color: #000;
    overflow: hidden;
  }
  .home-banner.loading .video-background {
    opacity: 0;
  }
  .video-background {
    position: absolute;
    top: 50%;
    left: 0;
    padding-top: 56.25%;
    width: 100%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: 2s opacity ease;
    transition: 2s opacity ease;
    opacity: 1;
  }
  .video-foreground,
  .video-background iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
  @supports (-ms-ime-align:auto) {
    .video {
      background-size: 100%;
    }
  }
  // .slick-slide class is jQuery generated
  .slick-slide {
    @include hero-slide;
    // @include overlay;
    position: relative;
    overflow: hidden;
    display: table;
    // @include breakpoint(xlarge) {
    //    clip-path: polygon(0 0, 0 97%, 48% 97%, 50% 100%, 52% 97%, 100% 97%, 100% 0);
    // }
  }
  .slide-caption {
    @include grid-column(12);
    padding: 1em;
    line-height: 1.25em;
    text-align: left;
    h2 {
      font-family: $font-family-serif;
      text-transform: uppercase;
      line-height: 1em;
      color: $primary-color;
      letter-spacing: .14em;
    }
    h3 {
      font-weight: 400;
    }
    p {
      padding: 1em;
    }
    sup {
      font-size: 30%;
      vertical-align: top;
      top: .5em;
      margin-left: -.5em;
    }
    sub {
      font-size: 30%;
      margin-left: -.25em;
      font-family: $body-font-family;
    }
    @include breakpoint(medium) {
      margin-top: 80px;
    }
    @include breakpoint(xlarge) {
      margin-top: 60px;
    }
  }
  article {
    z-index: 1;
    display: table-cell;
    vertical-align: middle;
  }
  .diamond {
    background-color: $white;
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
    opacity: .8;
    height: 800px;
    width: 800px;
    position: absolute;
    left: -400px;
    top: -80%;
  }
  @include breakpoint(medium) {
    .diamond {
      //left: -150px;
      //top: -100px;
      left: -300px;
      top: -150px;
    }
    .slide-caption {
      background: transparent;
      text-align: left;
      margin-left: 3em;
      h2 {
        font-size: f-header-size(small, h2);
      }
      h3 {
        font-weight: 400;
        font-size: f-header-size(small, h3);
      }
    }
  }
  @include breakpoint(large) {
    .diamond {
      left: -150px;
      top: -50px;
    }
    .slide-caption {
      h2 {
        @include header-size(h1);
      }
      h3 {
        font-weight: 400;
        font-size: f-header-size(medium, h3);
      }
    }
  }
  @include breakpoint(xlarge) {
    .diamond {
      height: 900px;
      width: 900px;
      left: -150px;
      top: 0;
    }
    .slide-caption {
      h2 {
        font-size: f-header-size(medium, h1) + 0.5;
      }
    }
  }
  .slick-next, .slick-prev {
    z-index: 2;
    opacity: .5;
    top: 45%;
    margin: 0 !important;
    width: 30px;
    height: 30px;
    &:before {
      font-family: flaticon;
      display: block;
      margin: 0 !important;
      padding: 0;
      position: relative;
      font-size: 50px;
      width: 30px;
      height: 30px;
    }
    &:hover {
      opacity: 1;
    }
  }
  .slick-next {
    right: 1% !important;
    &:before {
      text-indent: -80%;
      content: "\e008";
    }
  }
  .slick-prev {
    left: 1% !important;
    &:before {
      content: "\e009";
      text-indent: -50%;
    }
  }
  &.slick-initialized {
    opacity: 1;
    display: block;
  }
  ul.slick-dots {
    bottom: 2%;
    margin-left: 0;
  }
}
.tabs-explore {
  text-align: center;
  .tabs {
    border: none;
  }
  .tabs-title {
    float: none;
    display: inline-block;
    margin: 0 1px;
    > a {
      @include header-size(h4);
      text-transform: uppercase;
      letter-spacing: 1px;
      font-family: $header-font-family;
      color: $primary-color;
      font-weight: bold;
      padding: 1rem .5rem;
    }
    > a:focus, > a[aria-selected='true'] {
      background: none;
      color: $cyan;
    }
    &:after {
      content: '';
      display: block;
      height: 3px;
      width: 0;
      margin: 0;
      background: transparent;
      transition: width 0.25s ease, background-color 0.25s ease;
    }
    &:hover:after {
      width: 100%;
      background: $red;
    }
    &.is-active:after {
      width: 100%;
      background: $red;
    }
    @media only screen and (max-device-width: 640px) {
      > a {
        font-size: 1em;
        letter-spacing: .025em;
        padding: 1rem .5rem;
      }
    }
  }
  .tabs-content {
    border: none;
    text-align: left;
    position: relative;
    padding: 0;
    font-size: 1em;
    // background-color: $eggshell;
    background: linear-gradient(0deg, rgba($eggshell, 1) 0%, rgba($eggshell, .25) 100%);
  }
  .tabs-panel {
    transition: all 1s;
    opacity: 0;
    overflow: hidden;
    padding: 1em 0;
    .column {
      padding: .25em;
    }
    &.is-active {
      opacity: 1;
    }
  }
  @include breakpoint(large) {
    .tabs-title {
      margin: 0 1px;
      > a {
        @include header-size(h4);
        letter-spacing: 2px;
        padding: 1.25rem 1.5rem;
      }
    }
    .tabs-content {
      padding: 2em 2em 0 2em;
    }
  }
  .card {
    .card-section {
      background-color: $white;
    }
  }
  //.card {
  //  font-size: .9em;
  //  border: none;
  //  .title {
  //    color: $body-font-color;
  //    font-family: $font-family-serif;
  //    text-transform: uppercase;
  //    //letter-spacing: .25px;
  //    line-height: 1.15em;
  //    display: block;
  //  }
  //  .subtitle {
  //    font-family: $header-font-family;
  //    text-transform: uppercase;
  //    font-weight: bold;
  //    line-height: 1.25em;
  //    display: block;
  //    color: $primary-color;
  //  }
  //  p {
  //    font-size: .9em;
  //    color: $body-font-color;
  //  }
  //  .thumbnail {
  //    width: 100%;
  //    //max-height: 80px
  //  }
  //  @include breakpoint(large) {
  //    .title {
  //      font-size: 1.5em;
  //    }
  //  }
  //  img {
  //    width: 100%;
  //    display: block;
  //  }
  //  .button {
  //    margin: 1em 0 0 0;
  //    font-size: .85em;
  //  }
  //}
}
.clip-svg {
  width: 0;
  height: 0;
}
// .shell {
//    clip-path: url("#polygon-clip-rhombus");
//    width: 300px;
//    height:300px;
//    background-image: url('/assets/images/content/tn-shop.jpg');
// }
.hover-callouts {
  margin: 0 auto;
  .cell {
    position: relative;
  }
  .wrapper {
    position: absolute;
    height: 100%;
    width: 100%;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    vertical-align: middle;
  }
  .meta {
    height: 100%;
    text-align: center;
    border: 1px solid $transparent;
    padding: 1em;
    margin: 0;
    vertical-align: middle;
    z-index: 1;
    h3 {
      font-family: $font-family-serif;
      // @include header-size(h2);
    }
    h3,
    h4 {
      font-weight: 600;
      color: $primary-color;
      margin-bottom: 0;
      line-height: 1.25em;
      span {
        display: none;
      }
    }
    img {
      max-width: 120px;
    }
    hr {
      margin: 0.5em auto 1em auto;
    }
    p {
      color: $body-font-color;
      margin: .5em 0;
      line-height: 1.25em;
      font-weight: 600;
      font-size: .85em;
    }
    .button {
      transition-delay: 0s !important;
      &:hover {
        background-color: transparent;
        color: $red;
        border-color: $red;
      }
    }
  }
  .diamond {
    display: block;
    position: absolute;
    height: 100%;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    opacity: .75;
    right: 0;
    left: 0;
    top: 0;
    transition: all .25s;
    transform-origin: 50% 50% 0;
    fill: $white;
    z-index: 1;
  }
  > div {
    vertical-align: bottom;
    transition: all 0.5s;
    background-repeat: no-repeat !important;
    background-position: center center !important;
    background-size: 100%;
    overflow: hidden;
    background-clip: content-box !important;
    min-height: 300px;
    &:after {
      position: absolute;
      top: 0;
      content: "";
      height: 100%;
      width: 100%;
      background-color: rgba($white, 0.75);
      z-index: 0;
    }
    &:hover {
      .diamond {
        transform: scale(1.5);
      }
    }
    @include breakpoint(medium) {
      &:after {
        position: absolute;
        top: 0;
        content: "";
        height: 100%;
        width: 100%;
        background-color: rgba($primary-color, 0.5);
        z-index: 0;
      }
      background-size: cover !important;
      transition: all 0.25s;
      min-height: 400px;
      border-bottom: none;
      .meta {
        transform: translate(0px, 45%);
        transition: all 0.5s;
        h3, h4 {
          color: $primary-color;
          span {
            display: block;
            opacity: 0;
          }
        }
        p {
          opacity: 0;
          transition-delay: 0;
        }
        hr {
          opacity: 0;
          transition-delay: 0;
          width: 80%;
          margin-right: auto;
          margin-left: auto;
        }
        a {
          opacity: 0;
          transition-delay: 0;
        }
        img {
          transition: all .25s;
          max-width: 175px;
          margin-top: -75px;
        }
      }
      &:hover {
        .meta {
          transform: translate(0px, 20%);
          transition-delay: 0.25s;
          p {
            opacity: 1;
            transition: all 1s;
            transition-delay: 0.25s;
          }
          hr {
            opacity: 1;
            transition-delay: 0.25s;
            border-bottom: 1px solid $white;
          }
          a {
            opacity: 1;
            transition: all 1s;
            transition-delay: .5s;
          }
          h3,
          h4 {
            span {
              opacity: 1;
              transition-delay: 0.25s;
            }
            img {
              display: none;
            }
          }
        }
      }
    }
    &:hover {
      background-size: 120%;
    }
  }
}
.homepage {
  .hover-callouts {
    > div {
      @include breakpoint(medium) {
        &:nth-of-type(1) {
          &:after {
            background-color: rgba($primary-color, 0.75);
          }
        }
        &:nth-of-type(2) {
          &:after {
            background-color: rgba($red, 0.75);
          }
        }
        &:nth-of-type(3) {
          &:after {
            background-color: rgba($secondary-color, 0.75);
          }
        }
        &:nth-of-type(4) {
          &:after {
            background-color: rgba($primary-color, 0.75);
          }
        }
      }
    }
    .meta {
      p {
        margin-bottom: 1em;
      }
      @include breakpoint(medium) {
        max-width: 80%;
        margin: 0 auto;
      }
      @include breakpoint(xxlarge) {
        max-width: 50%;
        margin: 0 auto;
      }
    }
  }
}
.stats {
  margin-top: 2em;
  font-family: $header-font-family;
  font-weight: 300;
  line-height: 1em;
  text-align: center;
  .cell {
    margin-top: .5em;
    margin-bottom: .5em;
  }
  strong {
    font-weight: bold;
    display: block;
    font-size: 2.5em;
    line-height: 1em;
    color: $primary-color;
  }
  @include breakpoint(large) {
    font-size: 1.25em;
  }
}
.demographics {
  margin-bottom: 2em;
  .stat-list {
    @include flex-grid-row($behavior: collapse);
    justify-content: center;
    align-content: center;
    .statistic {
      @include flex-grid-column($columns: 3, $gutters: 0);
      text-align: center;
      position: relative;
    }
    div.bluediamond {
      display: block;
      margin: auto;
      width: 0;
      height: 0;
      border: 50px solid transparent;
      border-bottom-color: $primary-color;
      position: relative;
      top: -50px;
      vertical-align: middle;
      text-align: center;
      &:after {
        content: '';
        position: absolute;
        left: -50px;
        top: 50px;
        width: 0;
        height: 0;
        border: 50px solid transparent;
        border-top-color: $primary-color;
      }
      @include breakpoint(medium) {
        top: -60px;
        border: 60px solid transparent;
        border-bottom-color: $primary-color;
        &:after {
          left: -60px;
          top: 60px;
          border: 60px solid transparent;
          border-top-color: $primary-color;
        }
      }
    }
    span {
      display: block;
      margin: auto;
      position: absolute;
      left: 0;
      right: 0;
      z-index: 2;
      top: 23%;
      font-weight: bold;
      color: $white;
      font-size: 1em;
      @include breakpoint(medium) {
        top: 30%;
        font-size: 1.25em;
      }
    }
    strong {
      display: block;
      font-weight: normal;
      text-transform: uppercase;
      margin-top: .5em;
    }
  }
}
// Upcoming Event Widget
.event-upcoming {
  h3 {
    margin-bottom: 1em;
  }
  .dropdown-pane.event-info {
    text-align: left;
    font-size: .9em;
    background-color: $primary-color;
    color: $white;
  }
  text-align: left;
  margin-bottom: 1em;
  .card {
    background-color: $eggshell;
    border: none;
  }
  .event-datetime {
    display: block;
    padding: .5em 0;
    font-size: .8em;
    color: $gray;
    position: relative;
    .date, .tribe-event-date-start {
      font-weight: bold !important;
      position: relative;
      height: 50px;
      vertical-align: middle;
      width: 50px;
      line-height: 50px;
      z-index: 1;
      text-transform: uppercase;
      transition: all .2s;
      font-size: .9em !important;
      text-align: center;
      //&:after {
      //  padding: 0.25em;
      //  color: rgba($gray, .5);
      //}
      &:after {
        content: "";
        position: absolute;
        top: 0;
        display: block;
        background-color: $white;
        height: 50px;
        text-align: center;
        transform: rotate(-45deg);
        width: 50px;
        z-index: -1;
        transition: all .2s;
      }
      &:hover {
        color: $white;
        &:after {
          background-color: $secondary-color !important;
        }
      }
    }
    .time, .tribe-event-time {
      label {
        display: none;
      }
      .strong {
        display: block;
      }
      a {
        padding-left: .25em;
      }
    }
  }
  h4.event-title {
    font-family: $font-family-serif;
    color: $primary-color;
    text-transform: none;
    line-height: 1.25em;
    font-weight: normal;
    a {
      transition: all .25s ease-in-out;
      &:hover {
        color: $secondary-color;
      }
    }
    .ico.event-parking {
      margin-left: .15em;
      font-size: .65em;
    }
  }
  .event-venue {
    font-family: $font-family-serif;
    color: $gray;
    font-style: italic;
    line-height: 1em;
  }
  .directory-number {
    color: $white;
    padding: .15em .25em;
    min-width: 20px;
    text-align: center;
    background-color: $primary-color;
    font-size: .85em;
    display: inline-block;
    margin-right: .5em;
    &.shop {
      background-color: $primary-color;
    }
    &.dine {
      background-color: $red;
    }
    &.work {
      background-color: $primary-color;
    }
    &.play {
      background-color: $secondary-color;
    }
    &.services {
      background-color: $medium-gray;
    }
  }
  @include breakpoint(medium) {
    h4 {
      font-size: f-header-size(medium, h4);
      font-weight: bold;
    }
    .event-datetime {
      text-align: left;
      .date {
        font-size: f-header-size(small, h5);
        font-weight: 600;
        display: inline-block;
        //&:after {
        //  content: "";
        //}
      }
      .time {
        display: inline-block;
      }
    }
  }
  @include breakpoint(large) {
    .event-datetime {
      //.date {
      //  &:after {
      //    content: "|";
      //  }
      //}
    }
  }
}
.page-template-template-holidays {
  .carousel-hero, .slick-slide {
    @include breakpoint('medium') {
      height: 500px;
    }
    @include breakpoint('large') {
      height: 600px;
    }
    @include breakpoint('xlarge') {
      height: 700px;
    }
    @include breakpoint('xxlarge') {
      height: 800px;
    }
  }
  .hero {
    z-index: -1;
    &:after {
      content: "";
      display: block;
      position: absolute;
      bottom: -30px;
      background: url("../images/content/holiday-lights.png") center center repeat-x;
      width: 100%;
      height: 102px;
      //margin-top: -50px;
      z-index: 999;
    }
  }
}
// ABOUT
//////////////////////////////////////////////////////////////////////////
.landing, .page-template-template-holidays {
  .hover-callouts {
    > div {
      border: 8px solid $white;
    }
  }
  h2 {
    display: initial;
  }
  .hero {
    height: 300px;
    background-size: 100%;
    background-position: center center;
    //margin-bottom: 2.500em;
    position: relative;
    background-color: $dark-gray;
    @include overlay;
    .diamond {
      //clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
      // clip-path: url("#polygon-clip-rhombus");
      //background-color: $white;
      display: block;
      position: absolute;
      z-index: 0;
      height: 90%;
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;
      left: 50%;
      top: 55%;
      transition: all .25s;
      transform: translate(-50%, -55%);
      fill: rgba($white, .75);
    }
    .hero-caption-image {
      max-width: 150px;
      margin-top: -2em;
      @include breakpoint(medium) {
        max-width: 180px;
        margin-top: -3em;
      }
      @include breakpoint(large) {
        max-width: 200px;
        margin-top: -4em;
      }
      @include breakpoint(xlarge) {
        max-width: 240px;
        margin-top: -4em;
      }
      @include breakpoint(xxlarge) {
        max-width: 280px;
        margin-top: -4em;
      }
    }
    .hero-caption {
      color: white;
      text-align: center;
      position: absolute;
      top: 55%;
      left: 0;
      right: 0;
      transform: translate(0%, -55%);
      margin: auto;
      h1 {
        color: $primary-color;
        font-size: f-header-size(small, h1) + 1;
        line-height: 1em;
        text-transform: uppercase;
        font-family: $font-family-serif;
        margin: 0 auto;
      }
      h2 {
        color: $body-font-color;
        font-weight: 400;
        font-size: f-header-size(small, h2) - .5;
        display: inline-block;
        padding: .75em;
        position: relative;
        &:before {
          content: "";
          height: 8px;
          width: 50%;
          background-color: $secondary-color;
          background: url("../images/site/border-top.svg") center center repeat-x;
          position: absolute;
          margin-left: auto;
          margin-right: auto;
          top: 0;
          left: 50%;
          transition: all .25s;
          transform: translate(-50%, 0%);
        }
      }
      p {
        font-size: $paragraph-font-size;
        a {
          color: white;
          font-weight: 700;
        }
      }
    }
    @include breakpoint(medium) {
      height: 500px;
      background-size: cover !important;
      background-position: center bottom;
      .diamond {
        height: 60%;
      }
      .hero-caption {
        h1 {
          font-size: f-header-size(medium, h1);
        }
        h2 {
          font-size: f-header-size(small, h2) - .5;
        }
      }
    }
    @include breakpoint(large) {
      height: 600px;
      background-size: cover !important;
      .hero-caption {
        h1 {
          font-size: f-header-size(medium, h1) + 1;
        }
        h2 {
          font-size: f-header-size(small, h2) - .25;
        }
      }
    }
    @include breakpoint(xlarge) {
      height: 700px;
      .hero-caption {
        //h1 {
        //  font-size: f-header-size(medium, h1) + 2;
        //}
      }
    }
    @include breakpoint(xxlarge) {
      height: 800px;
      .hero-caption {
        //h1 {
        //  font-size: f-header-size(medium, h1) + 2;
        //}
      }
    }
  }
}
.explore {
  // .directory-image {
  //    &:hover {
  //       transform: scale(1.5);
  //       cursor:grab;
  //    }
  // }
  .directory-number {
    position: absolute;
    top: 0;
    right: 0;
    color: $white;
    padding: .25em .5em;
    min-width: 30px;
    text-align: center;
    background-color: $primary-color;
    z-index: 4;
    &.shop {
      background-color: $primary-color;
    }
    &.dine {
      background-color: $red;
    }
    &.play {
      background-color: $cyan;
    }
  }
  .coming-soon {
    position: absolute;
    top: 0;
    left: 0;
    color: $white;
    padding: .25em .5em;
    width: 100%;
    text-align: center;
    background-color: $red;
    font-weight: 600;
    z-index: 3;
  }
  .delivery-services, .shopfromhome, .operating-with-modifications {
    //test
    position: absolute;
    bottom: 0;
    left: 0;
    color: $white;
    padding: .5em .5em;
    width: 100%;
    text-align: center;
    background-color: $primary-color;
    font-weight: 600;
    z-index: 3;
  }
  .shopfromhome:before {
    font-family: Flaticon;
    font-style: normal;
    margin-right: .25em;
    content: "\e112";
  }
}
button.view-larger {
  position: absolute;
  bottom: 0;
  right: 1em;
  text-indent: 20px;
  &:before {
    content: "";
    position: absolute;
    display: block;
    height: 20px;
    width: 20px;
    top: 8px;
    left: 4px;
    background: url("../images/site/ico-zoom.svg") no-repeat top left;
    background-size: 20px;
  }
  &:hover {
    &:before {
      content: "";
      background-position: bottom left;
    }
  }
}
// SUBPAGES
.subpage {
  h2 {
    display: initial;
  }
  .hero {
    min-height: 300px;
    background-size: auto;
    background-position: center center;
    //margin-bottom: 2.500em;
    position: relative;
    background-color: $dark-gray;
    @include overlay;
    &:before {
      background: rgba($cyan, .5);
    }
    .hero-caption {
      color: white;
      text-align: center;
      transform: translate(0%, -50%);
      top: 50%;
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      h1,
      h2 {
        color: white;
        text-shadow: 1px 1px rgba($black, .25);
      }
      h2 {
        color: $body-font-color;
        font-weight: 400;
        font-size: f-header-size(small, h2) - .5;
        display: inline-block;
        padding: .75em;
        position: relative;
        &:before {
          content: "";
          height: 8px;
          //width: 50%;
          width: 132px;
          background-color: $secondary-color;
          background: url("../images/site/border-top.svg") center center repeat-x;
          position: absolute;
          margin-left: auto;
          margin-right: auto;
          top: 0;
          left: 50%;
          transition: all .25s;
          transform: translate(-50%, 0%);
        }
      }
      h1 {
        color: $primary-color;
        font-size: f-header-size(small, h1) + 1;
        line-height: 1em;
        text-transform: uppercase;
        font-family: $font-family-serif;
        margin: 0 auto;
      }
      p {
        font-size: $paragraph-font-size;
        a {
          color: white;
          font-weight: 700;
        }
      }
    }
    @include breakpoint(medium) {
      min-height: 300px;
      background-size: cover !important;
      background-position: center bottom;
      .hero-caption {
        margin: 50px auto auto auto;
        h1 {
          font-size: f-header-size(medium, h1);
        }
        h2 {
          font-size: f-header-size(small, h2) - .5;
        }
      }
    }
    @include breakpoint(large) {
      min-height: 350px;
      background-size: cover !important;
      .hero-caption {
        h2 {
          font-size: f-header-size(small, h2) - .25;
        }
      }
    }
    @include breakpoint(xlarge) {
      min-height: 400px;
    }
  }
}
// directions-parking
.tabs-directions-parking {
  text-align: center;
  .tabs {
    background-color: $snow;
  }
  .tabs-title {
    float: none;
    display: inline-block;
    margin: 0 1px;
    > a {
      @include header-size(h5);
      text-transform: uppercase;
      letter-spacing: 1px;
      font-family: $header-font-family;
      color: $primary-color;
      font-weight: bold;
      padding: 1rem .5rem;
    }
    > a:focus, > a[aria-selected='true'] {
      background: $white;
      color: $cyan;
    }
    &:after {
      content: '';
      display: block;
      height: 3px;
      width: 0;
      margin: 0;
      background: transparent;
      transition: width 0.25s ease, background-color 0.25s ease;
    }
    &:hover:after {
      width: 100%;
      background: $red;
    }
    &.is-active:after {
      width: 100%;
      background: $red;
    }
  }
  .tabs-content {
    border: none;
    text-align: left;
    position: relative;
    padding: 0;
  }
  .tabs-panel {
    transition: all 1s;
    opacity: 0;
    overflow: hidden;
    padding: 1em 0;
    &.is-active {
      opacity: 1;
    }
    .accordion-content {
      h5 {
        margin: 1em 0 0 0;
      }
      ul {
        list-style-type: disc;
        li {
          ul {
            margin-left: 2em;
            list-style-type: circle;
          }
        }
      }
    }
  }
  @include breakpoint(large) {
    .tabs-title {
      margin: 0 1px;
      > a {
        //@include header-size(h4);
        letter-spacing: 2px;
        padding: 1.25rem 1.5rem;
      }
    }
    .tabs-content {
      padding: 2em 2em 0 2em;
    }
  }
}
.accordion-item {
  border-bottom: 1px solid $silver;
  .accordion-title {
    font-weight: bold;
  }
  &.is-active {
    border: 1px solid $silver;
    .accordion-title {
      color: $red;
    }
  }
}
// SUBNAV
nav.subnav {
  text-align: center;
  background-color: $snow;
  align-items: center;
  align-content: center;
  .vertical {
    text-align: left;
    &:first-of-type {
      margin-bottom: 2em;
    }
    a {
      display: inline-block
    }
  }
  ul.menu {
    vertical-align: baseline;
    width: 100%;
    list-style-type: none;
    text-transform: uppercase;
    align-self: center;
    text-align: center;
    flex: 1 1 0;
    > li {
      position: relative;
      text-align: left;
      &.is-active:after {
        width: 100%;
        background: $red;
      }
      &:after {
        content: '';
        display: block;
        left: 0;
        height: 3px;
        width: 0;
        background: $primary-color;
        transition: width 0.5s ease, background-color 0.25s ease;
      }
      &.hover:after,
      &:hover:after {
        width: 100%;
        background: $red;
      }
      &.sel {
        background: $white;
        a {
          color: $cyan;
        }
        &:after {
          width: 100%;
          background: $red;
        }
      }
    }
    > li > a {
      @include header-size(h5);
      text-transform: uppercase;
      letter-spacing: 1px;
      font-family: $header-font-family;
      color: $primary-color;
      font-weight: bold;
      padding: 1.25rem 1.5rem;
      > a:focus, > a[aria-selected='true'] {
        background: $white;
        color: $cyan;
      }
    }
  }
}
.cdc-visit-icons {
  .card {
    padding: 0 2em;
    margin: 0 auto;
    border: none;
    img {
      max-width: 175px;
    }
    p {
      font-size: 1.25em;
      padding-top: 0;
      line-height: 1.25em;
      strong {
        color: $primary-color;
      }
    }
  }
}
.callout.primary-strong {
  background-color: $primary-color;
  color: $white;
  text-align: center;
  p {
    font-size: 1.25em;
    font-weight: bold;
  }
}
.callout-colors {
  .columns {
    background-color: $light-gray;
    &:nth-child(2) {
      background-color: lighten($light-gray, 2%);
    }
    &:nth-child(3) {
      background-color: lighten($light-gray, 4%);
    }
    &:nth-child(4) {
      background-color: lighten($light-gray, 6%);
    }
  }
  .callout {
    padding: 1em 0;
    background-color: $transparent;
    h4 {
      font-weight: 600;
    }
    @include breakpoint(large) {
      padding: 2em 1em;
    }
  }
}
.location {
  address.popup {
    ul {
      margin: 0;
      padding: 0;
      line-height: 1em;
      @include no-bullet;
      li {
        list-style-type: none;
        line-height: 1.25em;
        margin-bottom: 0;
        span {
          color: $gray;
          padding: 4px;
        }
      }
    }
    border-bottom: 0 solid #dddddd;
    padding: 0;
    margin: 0;
    white-space: nowrap;
    img {
      max-width: 320px;
      padding-bottom: 1em;
    }
    .show-on-map {
      display: none !important;
    }
  }
}
//Events

img.emoji {
  display: inline !important;
  border: none !important;
  box-shadow: none !important;
  height: 1em !important;
  width: 1em !important;
  margin: 0 0.07em !important;
  vertical-align: -0.1em !important;
  background: none !important;
  padding: 0 !important;
}

.tribe-events-view--month, .tribe-events-view--week {
  //.ico.event-parking {
  //  font-size: .75em;
  //  text-transform: uppercase;
  //  &:before {
  //    width: 14px;
  //    height: 14px;
  //  }
  //}
}
.parking-dropdown {
  background-color: $primary-color;
  color: $white;
  padding: .5em !important;
  border: none;
  p {
    font-size: .85em;
    margin-bottom: 0;
  }
}
.recurring-icon {
  display: inline-block;
  width: 12px;
}
.filters {
  padding-bottom: 1.0em;
  h3 {
    margin-bottom: 0.2em;
    font-size: 15px;
    display: block;
    img {
      margin: 0 4px 0 0;
    }
  }
  h4 {
    font-weight: bold;
  }
}
.button-group.filter-options, .button-group.filter-tags {
  margin: 0;
  list-style: none;
  border: none;
  font-size: 80%;
  display: inline-block;
  @include breakpoint(medium) {
    font-size: 100%;
  }
  .button {
    background-color: transparent;
    color: $gray;
    line-height: 1em;
    border: 1px solid $silver;
    font-size: .8em;
    &:hover {
      //  background-color: #fff;
      border: 1px solid $red;
    }
    &.active {
      color: $red;
      background-color: transparent;
      border: 1px solid $red;
    }
  }
  .clear {
    vertical-align: middle;
    padding: 0.85em 1em;
    -webkit-appearance: none;
    transition: background-color 0.25s ease-out, color 0.25s ease-out;
    line-height: 1;
    text-align: center;
    cursor: pointer;
    background-color: #ba0c2f;
    color: #fefefe;
    margin-right: 1px;
    margin-bottom: 1px;
    background-color: transparent;
    color: $gray;
    font-size: .8em;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: bold;
    &:hover {
      color: $red;
    }
  }
}
fieldset.filters-group {
  label {
    display: none;
  }
  .sort-select, .quicksearch {
    color: $gray;
    box-shadow: none;
    list-style: none;
    border: none;
    border-bottom: 1px solid $gray;
  }
}
.event-day-parking-rates {
  width: 40px;
  height: 40px;
  //clip-path: polygon(0 100%, 100% 100%, 100% 0);
  background-color: $white;
  color: $white;
  position: absolute;
  bottom: 1px;
  right: 1px;
  display: block;
  border-top-left-radius: 8px;
  button {
    z-index: 99999;
    display: block;
    position: relative;
    width: 40px;
    height: 40px;
    padding: 10px;
    //background-color: $blue;
  }
  // &:before {
  //    background: url("../images/site/ico-p-warning.svg") no-repeat bottom right;
  //    background-size: 20px;
  //    content: "";
  //    width: 50px;
  //    height: 50px;
  //    display: block;
  //    position: absolute;
  //    bottom: 4px;
  //    right: 4px;
  //    color: $white;
  //    //background-color: $blue;
  //
  //
  // }
  span {
    display: none;
  }
}
.events {
  @include flex-grid-row($behavior: collapse);
  justify-content: center;
}
.event {
  @include flex-grid-column($columns: 12, $gutters: 1);
  display: flex;
  transition: all 0.25s;
  overflow: hidden;
  text-align: center;
  //cursor: pointer;
  position: relative;
  background-clip: content-box !important;
  margin-bottom: 1px;
  align-items: flex-start;
  width: 100%;
  background-size: cover;
  background-position: center;
  z-index: 2;
  @include breakpoint(medium) {
    @include flex-grid-column($columns: 4, $gutters: 2) ;
    align-items: center;
    height: 300px;
    margin-bottom: 2px;
  }
  @include breakpoint(xlarge) {
    @include flex-grid-column($columns: 3, $gutters: 2) ;
    height: 300px;
  }
  &:before {
    content: "";
    position: absolute;
    width: calc(100% - 2px);
    height: 100%;
    min-height: 100%;
    z-index: 0;
    opacity: .8;
    top: 0;
    bottom: 0;
    left: 0;
    // default
    background-color: lighten($primary-color, .8);
  }
  background-color: lighten($primary-color, .8);
  .event-content {
    transition: all 0.25s;
    color: $white;
    padding: 1em;
    width: 100%;
    z-index: 1;
    h4 {
      color: $white;
      line-height: 1.25em;
      font-family: $font-family-serif;
      font-size: f-header-size(medium, h4);
    }
  }
  .event-description {
    transition: all, cubic-bezier(.25, .25, .02, 1), .5s;
    opacity: 1;
    p {
      margin: .5em auto 1em auto;
      font-size: 0.8em;
      line-height: 150%;
      color: $white;
    }
    .button {
      font-size: 8px;
      margin-bottom: 1em;
      border: 1px solid $white;
    }
    @include breakpoint(medium) {
      opacity: 0;
    }
  }
  .event-datetime {
    display: block;
    padding: .5em 0;
    font-size: .9em;
    .date {
      font-weight: 600;
      &:after {
        content: "|";
        padding: 0.25em;
        color: rgba($white, .5);
      }
    }
  }
  @include breakpoint(medium) {
    .event-content {
      h4 {
        font-size: f-header-size(medium, h4);
        font-weight: bold;
      }
    }
    .event-description {
      max-height: 0px;
      overflow: hidden;
      p {
        font-size: 0.9em;
      }
    }
    .event-datetime {
      display: block;
      position: absolute;
      top: 0px;
      left: 1px;
      padding: .5em;
      background-color: rgba($white, .85);
      border-right: 1px solid rgba($white, .5);
      border-bottom: 1px solid rgba($white, .5);
      color: $primary-color;
      text-align: left;
      .date {
        font-size: f-header-size(small, h5);
        font-weight: 600;
        display: inline-block;
        &:after {
          content: "";
        }
      }
      .time {
        display: inline-block;
      }
    }
    &:hover {
      .event-content {
        padding-top: 40px;
      }
      .event-description {
        opacity: 1;
        max-height: 250px;
      }
    }
  }
  @include breakpoint(large) {
    .event-datetime {
      .date {
        &:after {
          content: "|";
        }
      }
    }
  }
  &.concert {
    background-color: $red;
    &:before {
      background-color: $red;
    }
  }
  &.dine {
    background-color: $secondary-color;
    &:before {
      background-color: $secondary-color;
    }
  }
  &.movie {
    background-color: darken($secondary-color, 50%);
    &:before {
      background-color: $secondary-color;
    }
  }
  &.sport {
    background-color: $primary-color;
    &:before {
      background-color: $primary-color;
    }
  }
}
// EVENT CONTENT PAGE
//////////////////////////////////////////////////////
.event-content-page {
  .event-description {
    p {
      margin: .5em auto 1em auto;
      font-size: 0.8em;
      line-height: 150%;
    }
    .button {
      font-size: .6rem;
      border: 1px solid $white;
    }
  }
  .event-datetime {
    display: inline-block;
    padding: 0 0 1em 0;
    margin: 1em 0;
    font-size: 1em;
    border-bottom: 1px solid $light-gray;
    .date {
      font-weight: 600;
      display: block;
      font-size: f-header-size(small, h3);
      &:after {
        content: "|";
        padding: 0.25em;
        color: $light-gray;
      }
    }
    .time, .cost {
      color: $gray;
      strong {
        color: $body-font-color;
      }
    }
    .time {
      &:after {
        content: "|";
        padding: 0.25em;
        color: $light-gray;
      }
    }
  }
  .event-image, .tribe-events-event-image {
    padding: 0 0 1.5em 0;
    margin: 1em 0;
    border-bottom: 1px solid $light-gray;
    height: 300px;
    overflow: hidden;
    object-fit: contain;
    @include breakpoint(medium) {
      height: 500px;
    }
    img {
      height: 100%;
      object-fit: contain;
      margin: 0 auto;
    }
  }
  .event-details, .tribe-events-meta-group {
    margin: 1em 0;
    padding: 1em;
    background-color: $snow;
    h5, .tribe-events-single-section-title {
      display: block;
      margin-top: 1em;
      position: relative;
      padding-bottom: .25em;
      &:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 50%;
        height: 3px;
        background-color: $red;
      }
    }
    .columns {
    }
  }
  a:not(.button):hover {
    color: $red;
  }
  .event-link {
    &:before {
      font-family: Flaticon;
      font-size: 1em;
      font-style: normal;
      margin-right: 4px;
      content: "\e0b5";
    }
  }
  .calendar-button {
    @include button;
    margin-right: 1px;
    border-radius: 4px;
    background-color: $blue;
    &:before {
      font-family: Flaticon;
      font-size: 1em;
      font-style: normal;
      margin-right: 4px;
      color: $white;
      content: "\e02c";
    }
    &:hover {
      color: $white !important;
    }
  }
  address {
    .delimiter, .region, .postal-code {
      margin-right: .5em;
    }
    .events-gmap {
      display: block;
      padding: .5em 0
    }
  }
  h5 {
    font-weight: bold;
  }
  @include breakpoint(medium) {
    .event-description {
      overflow: hidden;
      p {
        font-size: 1em;
      }
    }
    .event-datetime {
      text-align: left;
      font-size: 1.25em;
      .date {
        font-size: f-header-size(medium, h4);
        font-weight: 600;
        display: inline-block;
        &:after {
          padding: 0.25em;
        }
      }
    }
  }
}
.event-back {
  //background-color: lighten($light-gray, 6%);
  a {
    position: relative;
    line-height: 1em;
    text-transform: uppercase;
    font-size: .9em;
    color: $gray;
    &:before {
      font-family: 'Flaticon';
      font-size: 30px;
      font-style: normal;
      padding: .5em;
      margin-right: .5em;
      vertical-align: middle;
      display: inline-block;
      content: "\f104";
      background-color: lighten($light-gray, 3%);
    }
    &:hover {
      color: lighten($primary-color, 20%);
      &:before, &:after {
        color: $red;
      }
    }
  }
}
.event-pagenation {
  vertical-align: top;
  display: flex;
  @include breakpoint(medium) {
    display: block;
  }
  .event-next, .event-prev, .tribe-events-nav-next a, .tribe-events-nav-previous a {
    position: relative;
    padding: 1em 0;
    display: inline-flex;
    align-items: center;
    font-size: .7em;
    width: 50%;
    @include breakpoint(medium) {
      width: 100%;
      font-weight: bold;
      display: block;
    }
    @include breakpoint(large) {
      font-size: .9em;
    }
    .event-title {
      line-height: 1.25em;
    }
    &:hover {
      color: lighten($primary-color, 20%);
      &:before, &:after {
        color: $red;
      }
    }
    &:before, &:after {
      font-weight: lighter;
      content: "";
      font-size: 30px;
      display: inline-block;
      font-family: "flaticon" !important;
    }
  }
  .event-next, .tribe-events-nav-next a {
    padding-right: 40px;
    text-align: right;
    &:after {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(0, -50%);
      width: 40px;
      height: 40px;
      content: "\e105" !important;
    }
  }
  .event-prev, .tribe-events-nav-previous a {
    padding-left: 40px;
    &:before {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(0, -50%);
      width: 40px;
      height: 40px;
      content: "\e00d" !important;
    }
  }
}
.event-related {
  h4 {
    position: relative;
    &:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 4px;
      background-color: $light-gray;
    }
  }
}
.event-card {
  margin-bottom: 1em;
  display: block;
  .event-tn {
    height: 200px;
    width: 100%;
    overflow: hidden;
    position: relative;
    clip-path: content-box;
    border-top: 2px solid lighten($light-gray, 6%);
    border-right: 2px solid lighten($light-gray, 6%);
    border-left: 2px solid lighten($light-gray, 6%);
    img {
      width: 100%;
      position: absolute;
      left: 50%;
      top: 50%;
      transition-timing-function: linear;
      transition: all 0.2s ease-in-out 0s;
      transform: translate(-50%, -50%);
    }
  }
  .event-content {
    padding: 1em;
    background-color: lighten($light-gray, 6%);
  }
  h5 {
    font-weight: bold;
  }
  .event-datetime {
    text-align: left;
    font-size: .8em;
    .date {
      display: inline-block;
      &:after {
        padding: 0.25em;
        content: "|";
      }
    }
  }
  &:hover {
    img {
      transform: translate(-50%, -50%) scale(1.1);
    }
    color: lighten($primary-color, 20%);
  }
}
// LEASING
//////////////////////////////////////////////////////////////////////////
form.leasing-request {
  label {
    display: none;
  }
  input {
    font-size: .9em;
    &:focus::placeholder {
      opacity: 0;
    }
  }
}
// Information
ul.news-list {
  margin: 0 0 2em 0;
  padding: 0;
  list-style-type: none;
  li {
    list-style-type: none;
    margin: 0 0 .5em;
    padding: 0 0 .5em;
    line-height: 1.25em;
    border-bottom: 1px solid $light-gray;
    em {
      color: $blue-gray;
      font-size: 0.90em;
      font-style: normal;
    }
    a {
      color: $blue;
      &:hover {
        color: $cyan;
      }
    }
    .date {
      color: $red;
      display: block;
    }
  }
}
// EVENT PLANNING
.rental-venues {
  &.callout {
    img {
      margin-bottom: 1em;
      @include breakpoint(medium) {
        margin-bottom: 0;
      }
    }
  }
}
// ANIMATIONS
//////////////////////////////////////////////////////////////////////////
.animate {
  opacity: 0;
}
.slideUp {
  animation-name: slideUp;
  animation-duration: 1s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
}
@keyframes slideUp {
  0% {
    transform: translateY(20%);
    opacity: 0;
  }
  25% {
    transform: translateY(-2%);
  }
  75% {
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
  }
}
.subscribe {
  h5 {
    margin-bottom: 0;
  }
  p {
    font-size: 0.8em;
    margin: 0 auto .5em auto;
  }
  .button {
    margin: 0 auto;
  }
}
.ctct-form-custom {
  .ctct-form-header {
    h2 {
      font-weight: 400;
      font-family: "Gotham", Helvetica, Arial, sans-serif !important;
      font-size: f-header-size(medium, h5) !important;
    }
  }
}
div.ctct-form-embed div.ctct-form-defaults h2.ctct-form-header {
  font-weight: 400;
  font-family: "Gotham", Helvetica, Arial, sans-serif !important;
  font-size: f-header-size(medium, h5) !important;
}
div.ctct-form-embed div.ctct-form-defaults p.ctct-form-text {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: normal;
  color: #0a0a0a;
}
div.ctct-form-embed div.ctct-form-defaults p.ctct-gdpr-text {
  line-height: 1.5;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  color: #CCC;
  margin-bottom: 24px;
}
div.ctct-form-embed div.ctct-form-defaults {
  color: #323232;
  background-color: #ffffff;
  border-radius: 0;
  padding: 1em 0 0 0 !important;
  font: inherit;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}
div.ctct-form-embed form.ctct-form-custom div.ctct-form-field {
  margin: 0 0 0px 0 !important;
  text-align: left;
}
.tribe-events-single {
  .tribe-related-events-thumbnail.aktiv {
    img {
      transform: translate(-50%, -50%) scale(1) !important;
    }
    a {
      padding-top: 100% !important;
    }
  }
  .event-datetime.tribe-events-schedule {
    h2 {
      font-size: 1.5rem;
      text-transform: none;
    }
  }
  .tribe-events-single-event-title {
    font-family: $font-family-serif;
    text-transform: uppercase;
    font-weight: normal !important;
  }
}
.tribe-events-single > .tribe_events > :not(.primary,.secondary,.tribe-related-events) {
  order: 2;
  width: 100%;
}
.tribe-events-related-events-title {
  font-family: $font-family-serif !important;
  font-weight: normal;
}
.tribe-events-venue-map > div, .tribe-events-venue-map > iframe {
  max-height: none !important;
}
@include breakpoint(medium) {
  .tribe-events-venue-map {
    margin-top: 0 !important;
    order: 1;
    width: 100% !important;
  }
}
.tribe-events-single .tribe-events-sub-nav .tribe-events-nav-next a, .tribe-events-single .tribe-events-sub-nav .tribe-events-nav-previous a {
  font-weight: bold;
  color: $blue !important;
  transition: all .25s;
  &:hover {
    color: $cyan !important;
  }
}
a.tribe-events-pro-photo__event-featured-image-link {
  display: block;
  overflow: hidden !important;
  padding-top: 100% !important;
  position: relative !important;
  object-fit: contain;
}
.tribe-events-pro .tribe-events-pro-photo__event-featured-image {
  bottom: 0 !important;
  left: 50% !important;
  position: absolute !important;
  right: 0 !important;
  top: 50% !important;
  transform: translate(-50%, -50%) scale(1) !important;
  object-fit: contain;
}
.tribe-events-pro-photo {
  button.ico {
    display: inline;
  }
  .tribe-events-pro-photo__event {
    .tribe-events-pro-photo__event-featured-image-wrapper a {
      position: relative;
      background-color: $light-gray;
      //&:after {
      //  content: "";
      //  position: absolute;
      //  width: 100%;
      //  height: 100%;
      //  min-height: 100%;
      //  z-index: 0;
      //  opacity: .7;
      //  top: 0;
      //  bottom: 0;
      //  left: 0;
      //  // default
      //  background-color: lighten($primary-color, .8);
      //}
    }
    &.cat_concert {
      .tribe-events-pro-photo__event-featured-image-wrapper a {
        //background-color: $red;
        //&:after {
        //  background-color: $red;
        //}
      }
    }
    &.cat_dine {
      .tribe-events-pro-photo__event-featured-image-wrapper a {
        //background-color: $secondary-color;
        //&:after {
        //  background-color: $secondary-color;
        //}
      }
    }
    &.cat_movie {
      .tribe-events-pro-photo__event-featured-image-wrapper a {
        //background-color: darken($secondary-color, 50%);
        //&:after {
        //  background-color: $secondary-color;
        //}
      }
    }
    &.cat_sport {
      .tribe-events-pro-photo__event-featured-image-wrapper a {
        //background-color: $primary-color;
        //&:after {
        //  background-color: $primary-color;
        //}
      }
    }
    &.cat_play {
      .tribe-events-pro-photo__event-featured-image-wrapper a {
        //background-color: $secondary-color;
        //&:after {
        //  background-color: $secondary-color;
        //}
      }
    }
  }
}
// FLEXIBLE CONTENT BLOCKS
.photo-bgd-callout {
  background-position: center center !important;
  background-size: cover;
  .content {
    margin: 2em auto;
    padding: 1em;
    background-color: rgba($white, .9);
    text-align: center;
  }
  @include breakpoint(medium) {
    background-attachment: fixed !important;
    .content {
      margin: 4em auto;
      padding: 1em 2em;
      background-color: rgba($white, .9);
    }
  }
}
.cards-stak {
  .card {
    border: none;
  }
  .card-description {
    margin-bottom: 1em;
  }
  .title {
    font-family: $font-family-serif;
    font-weight: normal;
    text-transform: uppercase;
    line-height: 1.15em;
    display: block;
  }
  .subtitle {
    font-family: $header-font-family;
    text-transform: uppercase;
    font-weight: bold;
    line-height: 1.25em;
    display: block;
  }
  .button {
    margin-top: .5em;
  }
  @include breakpoint(large) {
    &.title {
      font-size: 1.15em;
    }
  }
}
// VIDEO
.flex-video {
  iframe {
    border: 0;
  }
}
.video-backbground {
  background-color: $light-gray;
  padding: 15px;
  margin: 0 15px !important;
}
.video-player {
  // background-color: #fff;
  padding-bottom: 5px;
  .poster {
    position: relative;
    img {
      width: 100%;
    }
  }
  .play-button {
    position: absolute;
    width: 80px;
    height: 80px;
    margin: auto;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    transition: opacity 0.5s ease;
    &:hover {
      opacity: 0.5;
    }
  }
}
.split-image {
  img {
    margin-bottom: 2em;
    @include breakpoint(medium) {
      margin-bottom: 0;
    }
  }
}
h3,
h4 {
  &.underline {
    position: relative;
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 4px;
      background-color: $gray;
    }
  }
  line-height: 1.15em;
}
.align-middle,
.collapse,
.fill-image {
  .content {
    padding: 2em;
  }
  @include breakpoint(xlarge) {
    .content {
      padding: 4em;
    }
  }
}
.fill-image {
  .columns {
    min-height: 200px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    @include breakpoint(medium) {
      background-size: auto 100%;
    }
  }
}
.alternating-staks {
  .stak {
    &:nth-child(odd) {
      background-color: $light-gray;
    }
  }
  img.thumbnail {
    margin: 0.5em auto;
    width: 90%;
  }
}
.gallery-photo {
  &.slick-slider {
    margin-bottom: 4px !important;
  }
  > div {
    // max-width: 320px;
    overflow: hidden;
    vertical-align: middle;
    margin-bottom: 0;
    img {
      //max-width: 640px;
      width: 100%;
      vertical-align: middle;
      margin: 0 auto;
      transform: scale(1);
      transition: transform 0.25s;
    }
  }
}
.gallery-thumb {
  //max-width: 640px;
  max-height: 98px;
  background-color: $white;
  div.slick-slide {
    //min-width: 120px;
    max-height: 90px !important;
    overflow: hidden;
    vertical-align: middle;
    margin: 0;
    text-align: center;
    margin: 0 2px;
    .slick-list {
      max-height: 90px;
    }
    img {
      max-width: 120px;
      min-height: 90px;
      vertical-align: middle;
      padding: 0 4px 0 0;
      margin: 0 auto;
    }
    @include breakpoint(large) {
      img {
        max-width: 150px;
        min-height: 120px;
      }
    }
  }
  .slick-next,
  .slick-prev {
    top: 0;
    background-color: rgba($white, .9);
    z-index: 1;
    height: 100%;
    &:before {
      color: $black;
    }
  }
}
.photo-gallery {
  justify-content: center;
  text-align: center;
}
// BLOG
// Article
.off-canvas.position-left {
  background-color: $white;
  aside {
    padding: 1em;
  }
}
.more-articles {
  padding-top: 2em;
  .button {
    border-radius: $button-radius;
  }
}
.article-cards, .tease-post {
  .card {
    transition: all 0.5s;
    display: block;
    background-color: $white;
    overflow: hidden;
    border: none;
    //margin: .9em;
    p {
      font-size: .8em;
      margin: 1.5em auto 1.75em;
    }
    .date {
      color: $gray;
      font-size: .8em;
    }
    .article-thumb {
      width: 100%;
      display: block;
      position: relative;
    }
    .article-categories {
      margin-bottom: 1em;
      a {
        text-transform: uppercase;
        font-size: .8em;
        color: $secondary-color;
        font-weight: bold;
        &:hover {
          color: lighten($primary-color, 8%);
        }
        &:before {
          content: ", ";
        }
      }
      a:first-of-type {
        &:before {
          content: "";
        }
      }
    }
    &:hover {
      img {
        width: 110%;
      }
    }
  }
  @include breakpoint(medium) {
    .card {
      transition: all 0.25s;
      display: block;
      margin: .9em;
      .card-section {
        transition: all 0.5s;
        padding: 1.5em 1em;
      }
    }
    padding-bottom: 2em;
  }
}
.breadcrumbs {
  margin-top: 2em;
  vertical-align: middle;
  font-size: 0.85em;
  color: $medium-gray;;
  a {
    color: $secondary-color;
    &:hover {
      text-decoration: underline;
    }
  }
  span {
    //font-size: 1em;
  }
  .breadcrumb_last {
    color: $medium-gray;
    cursor: not-allowed;
  }
}
.pagination-block {
  text-align: center;
  border-top: 2px solid $light-gray;
  padding-top: 2em;
  .disabled {
    color: lighten($gray, 20%);
  }
  .prev {
    float: left;
  }
  .next {
    float: right;
  }
  .current {
    font-weight: bold;
  }
}
.page-template-page-blog, .article {
  aside {
    h4 {
      font-size: .9em;
      letter-spacing: 1px;
      text-transform: uppercase;
      position: relative;
      padding-bottom: 0.25em;
      display: block;
      margin-bottom: 1em;
      color: $primary-color;
      &:after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 3px;
        background-color: $red;
      }
    }
  }
}
.article {
  margin-top: 5.625em;
  padding: 2em;
  border-top: 2px solid $light-gray;
  h1 {
    font-family: $font-family-serif;
    color: $primary-color;
    font-weight: normal;
    text-transform: uppercase;
    font-size: f-header-size(medium, h2) - .5;
  }
  p {
    a:not(.button) {
      color: lighten($primary-color, 20%);
      &:hover {
        color: $primary-color;
        text-decoration: underline;
      }
    }
  }
  .cat-title-link {
    a {
      padding: 0 .25em;
      &:after {
        content: ","
      }
      &:last-of-type {
        &:after {
          content: "";
        }
      }
    }
  }
  .featured-image {
    margin-bottom: 2em;
  }
  .article-pagenation {
    margin: 2em auto;
    padding: 2em;
    border-top: 2px solid $light-gray;
    a {
      vertical-align: middle;
      font-weight: bold;
      transition: all .25s;
      line-height: 1em;
      display: block;
      color: $navy-blue;
      &:hover {
        color: $secondary-color !important;
      }
      &:before {
        background: url("data:image/svg+xml;charset=utf-8,%3Csvg width='12' height='20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.916 17.841L10 19.757l-9.9-9.9L10-.041l1.916 1.916-7.983 7.984z' fill='%235d5d5d'/%3E%3C/svg%3E") 0/7px no-repeat;
        content: "";
        background-size: 9px;
        display: inline-block;
        height: 20px;
        vertical-align: middle;
        width: 11px;
        margin-right: .5em;
        margin-top: -2px;
      }
    }
    @include breakpoint(medium) {
      justify-content: space-between;
      .next-post {
        text-align: right;
        a {
          &:before {
            background: none;
          }
          &:after {
            background: url("data:image/svg+xml;charset=utf-8,%3Csvg width='12' height='20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.084 2.159L2 .243l9.9 9.9L2 20.041.084 18.126l7.983-7.984z' fill='%235d5d5d'/%3E%3C/svg%3E") 100%/7px no-repeat;
            content: "";
            background-size: 9px;
            display: inline-block;
            height: 20px;
            vertical-align: middle;
            width: 11px;
            margin-left: .5em;
            margin-top: -2px;
          }
        }
      }
    }
  }
}
.recent-articles-list {
  margin-bottom: 1em;
  img {
    margin-right: 1em;
  }
  .article-date {
    font-size: .8em;
    margin-bottom: 0;
  }
  .article-title {
    h6 {
      margin-top: 0;
    }
    a:hover h6 {
      //   color: lighten($primary-color, 8%);
    }
  }
}
.category-list {
  a {
    padding: 0.7rem 1rem .7rem 0;
    display: inline;
    font-size: .8em;
  }
  .article-count {
    display: inline-block;
    width: 1.5em;
    font-size: 1em;
    color: $gray;
    //margin-right:  1rem;
  }
}
.bnr-venues {
  width: 100%;
  height: 400px;
  z-index: 99999;
  @include breakpoint(large) {
    height: 600px;
  }
}
.map-venues {
  width: 100%;
  height: 100% !important;
}
address {
  h3 {
    display: inline-block;
    &:after {
      content: '';
      display: block;
      height: 3px;
      width: 0;
      margin: 0;
      background: transparent;
      transition: width 0.25s ease, background-color 0.25s ease;
    }
    &:hover:after {
      width: 100%;
      background: $red;
    }
    &.sel {
      &:after {
        width: 100%;
        background: $red;
      }
    }
  }
}
address.popup {
  text-align: center;
  margin: 0;
  .venue-description {
    display: none;
  }
  .medium-6 {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0
  }
  img {
    max-width: 220px;
    margin: 0 auto;
  }
  a {
    pointer-events: none;
  }
  h3 {
    font-size: f-header-size(small, h3);
  }
}
//Instagram Addition
[data-header-size=medium].sbi-header-type-text {
  font-size: f-header-size(small, h3) !important;
  @include breakpoint(medium) {
    font-size: f-header-size(medium, h3) !important;
  }
}
.sbi-header-type-text {
  text-transform: uppercase;
  font-family: $font-family-serif;
  text-align: center;
  box-sizing: border-box;
  line-height: 1.2;
  margin: 0 auto;
  padding: 5px 5px 15px;
  width: 100%;
}
.align-center > .content-slider > .slick-list > .slick-track {
  margin: 0 auto;
}
// CONTENT BUILDER
.content-slider {
  margin: 1em 0;
  .slick-list {
  }
  .slick-slide {
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
    &:active {
      cursor: grabbing;
      cursor: -moz-grabbing;
      cursor: -webkit-grabbing;
    }
  }
  .slick-dots {
    margin-left: 0;
  }
  .slick-next,
  .slick-prev {
    background-color: $transparent;
    z-index: 1;
    height: 40px;
    width: 45px;
    border-radius: 100%;
    bottom: 45%;
    //border: 1px solid $primary-color;
    &:before {
      color: $primary-color;
      font-size: 26px;
      font-weight: 400;
      height: 40px;
      width: 40px;
      text-align: center;
      justify-content: center;
      margin-left: 0;
      margin-top: 6px;
    }
  }
  .slick-prev {
    right: unset;
    left: -24px;
  }
  .slick-next {
    right: -20px;
    &:before {
    }
  }
  @include breakpoint(medium) {
    margin: 1.5em 0;
    .slick-dots {
      margin: 0 0 1em 0;
    }
  }
  @include breakpoint(xlarge) {
    .slick-prev {
      left: -44px;
      &:before {
        font-size: 30px;
      }
    }
    .slick-next {
      right: -40px;
      &:before {
        font-size: 40px;
      }
    }
  }
}
.callout.white {
  color: $white;
  @include box-shadow;
  padding: 2em;
  .button.hollow {
    border-color: rgba($white, .8);
    color: rgba($white, .8);
    transition: all .25s;
    &:hover {
      border-color: rgba($white, 1);
      color: rgba($white, 1);
      transform: translate(0, -4px);
    }
  }
}
figure {
  position: relative;
  z-index: 0;
  &.overlay {
    background-color: transparent !important;
    &:before {
      @include overlay-solid;
    }
  }
  &.bgd-primary {
    &:before {
      background: $primary-color !important;
    }
  }
  &.bgd-secondary {
    &:before {
      background: $secondary-color !important;
    }
  }
  &.bgd-success {
    &:before {
      background: $success-color !important;
    }
  }
  &.bgd-warning {
    &:before {
      background: $warning-color !important;
    }
  }
  &.bgd-alert {
    &:before {
      background: $alert-color !important;
    }
  }
}
.module-cards {
  //background-color: $light-gray;
  .card-description {
    margin-bottom: 1em;
    font-size: 90%;
  }
  h3 {
    font-weight: 300;
    font-size: f-header-size(small, h3);
  }
  .card-section {
    .button {
      margin-top: 1em;
    }
  }
}
.button-group {
  display: flex;
}
.tease-vendor {
}
.alt-stak {
  .card {
    .card-section {
      background-color: $white;
    }
  }
}
.alt-stak .event-upcoming .event-datetime .date:after, .alt-stak.event-upcoming .event-datetime .tribe-event-date-start:after {
  background-color: $light-gray;
}
.card {
  font-size: .9em;
  border: none;
  .card-section {
    background-color: $snow;
  }
  .title {
    color: $body-font-color;
    font-family: $font-family-serif;
    text-transform: uppercase;
    //letter-spacing: .25px;
    line-height: 1.15em;
    display: block;
  }
  .subtitle {
    font-family: $header-font-family;
    text-transform: uppercase;
    font-weight: bold;
    line-height: 1.25em;
    display: block;
    color: $primary-color;
  }
  p {
    font-size: .9em;
    color: $body-font-color;
  }
  .thumbnail {
    width: 100%;
    //max-height: 80px
  }
  @include breakpoint(large) {
    .title {
      font-size: 1.5em;
    }
  }
  img {
    width: 100%;
    display: block;
  }
  .button {
    margin: 1em 0 0 0;
    font-size: .85em;
  }
}
.alt-stak {
  .card {
    .card-section {
      background-color: $white;
    }
  }
}